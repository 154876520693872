import type { PluginExtensionLinkConfig } from '@grafana/data';

export const onEscalateInServiceClick: PluginExtensionLinkConfig<{ teamName?: string }>['onClick'] = async (
  _event,
  { context }
): Promise<undefined> => {
  const {
    rootStore: { directPagingStore },
  } = await import('@grafana-irm/oncall-state');
  if (context?.teamName == null) {
    directPagingStore.openEscalationDrawerOnServicePage();
  } else {
    const { TeamsApi } = await import('@grafana-irm/features/teams');
    const team = await TeamsApi.getTeamByName(context.teamName, { only_include_notifiable_teams: true });
    directPagingStore.openEscalationDrawerOnServicePage({
      defaultTeam: team,
    });
  }
};

const createEscalationInServiceLinkConfig = {
  title: 'Escalate',
  description: 'Create an escalation',
  category: 'IRM',
  targets: ['grafana-slo-app/serviceActions/v1'],
  onClick: onEscalateInServiceClick,
};

export default createEscalationInServiceLinkConfig;
