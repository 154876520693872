import { lazy, Suspense } from 'react';

import { setClient } from '@grafana-irm/core/client';
import {
  addExtensionComponent,
  addExtensionLink,
  exposeComponent,
  PluginId,
  registerPluginServiceWorker,
} from '@grafana-irm/core/plugin';
import { queryClient } from '@grafana-irm/core/queryClient';
import { AppPlugin, type PluginConfigPageProps, type PluginMeta, PluginExtensionPoints } from '@grafana/data';
import { LoadingPlaceholder } from '@grafana/ui';

import { QueryClientProvider } from '@tanstack/react-query';

import createEscalationInServiceLinkConfig from './extensions/createEscalationInServiceLinkConfig';
import type { WhoIsOnCallForServiceProps } from './extensions/IRMWhoIsOnCallForService';
import pluginJson from './plugin.json';
import type { IrmAppRootProps, IRMPluginMetaJSONData } from './types';
import { GrafanaInternalApiClient } from './utils/GrafanaInternalApiClient';

try {
  setClient(new GrafanaInternalApiClient());
} catch (err) {
  console.error('Error setting up GrafanaApiClient: ', err);
}
const IncidentErrorBoundary = lazy(() =>
  import('@grafana-irm/components').then((mod) => ({ default: mod.IncidentErrorBoundary }))
);

const MinimalAppContextProvider = lazy(async () => ({
  default: (await import('@plugins/grafana-incident-app')).MinimalAppContextProvider,
}));

const ServiceCenterIncidentTable = lazy(() =>
  import('@plugins/grafana-incident-app').then((mod) => ({ default: mod.ServiceCenterIncidentTable }))
);

const LazyApp = lazy(() => import('./App'));
const LazyAppConfig = lazy(() => import('./pages/PluginConfigPage/PluginConfigPage'));
const LazyIRMProfileSettings = lazy(() => import('./extensions/IRMProfileSettings'));
const LazyIRMWhoIsOnCallForService = lazy(() => import('./extensions/IRMWhoIsOnCallForService'));

void registerPluginServiceWorker(PluginId.Irm, process.env.CURRENT_VERSION);

function App(props: IrmAppRootProps) {
  return (
    <Suspense fallback={<LoadingPlaceholder text="" />}>
      <LazyApp {...props} />
    </Suspense>
  );
}

function PluginConfigPage(props: PluginConfigPageProps<PluginMeta<IRMPluginMetaJSONData>>) {
  return (
    <Suspense fallback={<LoadingPlaceholder text="" />}>
      <LazyAppConfig {...props} />
    </Suspense>
  );
}

function IRMProfileSettings() {
  return (
    <Suspense>
      <LazyIRMProfileSettings />
    </Suspense>
  );
}

function IRMWhoIsOnCallForService(props: WhoIsOnCallForServiceProps) {
  return (
    <Suspense>
      <LazyIRMWhoIsOnCallForService {...props} />
    </Suspense>
  );
}

const plugin = new AppPlugin<IRMPluginMetaJSONData>().setRootPage(App).addConfigPage({
  title: 'Configuration',
  icon: 'cog',
  body: PluginConfigPage,
  id: 'configuration',
});

addExtensionComponent<IRMPluginMetaJSONData>(plugin, {
  title: 'IRM',
  description: 'IRM settings',
  component: IRMProfileSettings,
  targets: ['grafana/user/profile/tab'],
});

addExtensionLink<IRMPluginMetaJSONData>(plugin, createEscalationInServiceLinkConfig);

addExtensionLink<IRMPluginMetaJSONData>(plugin, {
  title: 'Declare incident',
  description: 'Declare an incident and attach the panel context to it',
  category: 'Incident',
  icon: 'fire',
  targets: [PluginExtensionPoints.DashboardPanelMenu],
  onClick: async (...args) => {
    const { getDeclareIncidentClickHandler } = await import('./utils/getDeclareIncidentClickHandler');
    await getDeclareIncidentClickHandler({ shouldAttachURL: true })(...args);
  },
});

addExtensionLink<IRMPluginMetaJSONData>(plugin, {
  title: 'Declare an incident', // titles of each extension need to be unique
  description: 'Declare an incident',
  targets: [PluginExtensionPoints.ExploreToolbarAction],
  category: 'Incident',
  icon: 'fire',
  onClick: async (...args) => {
    const { getDeclareIncidentClickHandler } = await import('./utils/getDeclareIncidentClickHandler');
    await getDeclareIncidentClickHandler()(...args);
  },
});

addExtensionLink<IRMPluginMetaJSONData, { teamName?: string; serviceName?: string }>(plugin, {
  title: 'Declare incident',
  description: 'Declare incident',
  targets: ['grafana-slo-app/serviceActions/v1'],
  category: 'IRM',
  onClick: async (...args) => {
    const { context } = args[1];
    const { getDeclareIncidentClickHandler } = await import('./utils/getDeclareIncidentClickHandler');
    await getDeclareIncidentClickHandler({
      labels:
        context?.serviceName != null
          ? [
              {
                key: 'service_name',
                value: context.serviceName,
              },
            ]
          : undefined,
    })(...args);
  },
});

exposeComponent(plugin, {
  id: 'grafana-irm-app/service-center-table-component/v1',
  title: 'Service center table component',
  description: 'Service center table component',
  component: () => {
    return (
      <Suspense>
        <MinimalAppContextProvider>
          <QueryClientProvider client={queryClient}>
            <IncidentErrorBoundary fallbackRender={() => <div>There was an error loading Incidents</div>}>
              <ServiceCenterIncidentTable></ServiceCenterIncidentTable>
            </IncidentErrorBoundary>
          </QueryClientProvider>
        </MinimalAppContextProvider>
      </Suspense>
    );
  },
});

exposeComponent(plugin, {
  id: `${pluginJson.id}/oncall-now/v1`,
  title: 'On-call now',
  description: 'A component that shows who is on-call for particular service',
  component: IRMWhoIsOnCallForService,
});

export { plugin };
